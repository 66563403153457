/* eslint-disable indent */
import { isMobile } from "react-device-detect";
import tw from "tailwind-styled-components";
import { HeaderSection } from "../../components";
import { ContentLayout } from "../../components/Layout/ContentLayout";
import { PageLayout } from "../../components/Layout/PageLayout";
import { MobileAppDownloadBanner } from "../../components/MobileAppDownloadBanner/MobileAppDownloadBanner";
import { PageVariables } from "../../services/pageVariables";
import {
  generatePreFooterSingleType,
  generatePreFooterType,
  renderSection,
} from "../../services/section";
import { textCompiler } from "../../services/textCompiler";
import {
  FooterSectionType,
  NavigationBarType,
  PageSectionType,
  PreFooters,
  PreFooterSectionItem,
  PreFootersType,
  SectionsType,
  SectionTypeEnum,
  SimplesSectionType,
} from "../../types";
import { PreFooterTypesSection } from "../components/Sections/PreFooterTypesSection";

interface PageProps {
  pageSections: PageSectionType<SectionsType>[];
  navBarSection: PageSectionType<NavigationBarType>;
  footerSection: PageSectionType<FooterSectionType>;
  preFooters?: PreFooters;
  preFooter?: PreFooterSectionItem[];
  preFooterType?: PreFootersType;
  hasBooker: boolean;
  reassurances?: string[];
  variables?: PageVariables;
  additionalCta?: boolean;
}

export function Page(props: PageProps): JSX.Element {
  const {
    pageSections,
    navBarSection,
    footerSection,
    hasBooker,
    preFooters,
    preFooter,
    preFooterType,
    reassurances,
    variables,
    additionalCta,
  } = props;

  //console.log(pageSections);

  const isFirstSectionSimple =
    pageSections[0]?.name === SectionTypeEnum.SIMPLES_SECTION;
  const firstSection = pageSections[0].section as SimplesSectionType;

  //console.log(firstSection);

  return (
    <PageLayout>
      {renderSection(navBarSection, variables, !hasBooker)}
      <MobileAppDownloadBanner />
      {isFirstSectionSimple && (
        <Mobile>
          <HeaderSection
            level="h1"
            title={textCompiler(firstSection.title, variables)}
            text={textCompiler(firstSection.text, variables)}
          />
        </Mobile>
      )}
      <ContentLayout reassurances={reassurances} hasBooker={hasBooker}>
        {pageSections.map((section, i) =>
          renderSection(
            section,
            variables,
            !hasBooker,
            isFirstSectionSimple && i === 0 && isMobile,
            section.name !== SectionTypeEnum.CARDS_SECTION ||
              (section.section as any)?.slug === "three-offers" ||
              additionalCta !== undefined
              ? additionalCta
              : false,
            undefined,
            i === 0
          )
        )}
      </ContentLayout>
      {preFooter?.length &&
        preFooter.length > 0 &&
        renderSection(
          generatePreFooterSingleType(preFooter),
          variables,
          !hasBooker
        )}
      {preFooters &&
        renderSection(generatePreFooterType(preFooters), variables, !hasBooker)}
      {preFooterType && <PreFooterTypesSection data={preFooterType} />}
      {renderSection(footerSection)}
    </PageLayout>
  );
}

const Mobile = tw.div`
  lg:hidden
  px-4
  mt-8
`;
