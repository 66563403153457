/* eslint-disable indent */
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { SeoHead } from '../containers/components';
import { Page } from '../containers/pages/page';
import { getFooterCache, getNavBarCache } from '../services/cache';
import {
  FooterSectionType,
  NavigationBarType,
  PageBaseProps,
  PageSectionType,
} from '../types';
import { v4 as uuidv4 } from 'uuid';

export interface HomeProps {
  navBarSection: PageSectionType<NavigationBarType>;
  footerSection: PageSectionType<FooterSectionType>;
}

export default function Home(props: HomeProps): JSX.Element {
  return (
    <>
      <SeoHead />
      <Page
        pageSections={[{ id: uuidv4(), name: '404', section: {} }]}
        navBarSection={props.navBarSection}
        footerSection={props.footerSection}
        hasBooker={true}
      />
    </>
  );
}

export async function getStaticProps({
  locale,
}: {
  locale: string;
}): Promise<PageBaseProps> {
  const navBarSection = await getNavBarCache();
  const footer = await getFooterCache();
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      navBarSection: navBarSection,
      footerSection: footer,
    },
    revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_TIME),
  };
}
